.container {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.22);
  width: 100%;
  height: 75%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px 30px 20px 30px;
  margin-bottom: 30px;
}
.col {
  text-align: left;
  display: flex;
  align-items: left;
  /*justify-content: center;*/
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button {
  background: #12B623;
  color: black;
  font-size: 14px;
  text-align: center;
  height: 33px;
  border-radius: 10px;
  width: 182px;
  border: none;
  font-weight: 600;
}
