.blue {
  color: #053e5e;
  margin-right: 15px;
  font-family: "Montserrat";
}
.yellow {
  color: #f6d030;
}
.container {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #f2f2f2;
}
.button {
  background-color: #f6d030;
  color: #053e5e;
  border-color: white;
}
.button:hover {
  background-color: #FDCE04;
  color: #053e5e;
  border-color: #053e5e;
}