.container {
  width: 100%;
  margin-top: 20px;
}

.formControl {
  border-radius: 20px;
  height: 38px;
}

.longAnswer {
  border-radius: 20px;
}

.optionalHeading {
  font-size: 18px;
  margin: 0px;
  padding-left: 20px;
}

.optionalSubhedding {
  font-size: 12px;
  margin: 0px;
  padding: 0px 0px 10px 20px;
}

.requiredHeading {
  font-size: 18px;
  margin: 0px;
  padding: 0px 0px 10px 20px;
}
