.backgroundTitle {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #053e5e;
  text-align: left;
  color: white;
  align-items: center;
}

.background {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #053e5e;
  text-align: center;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}
.centerVertically {
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  height: 100vh;
}
