.footer {
  margin-top: 5vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  height: 150%;
  text-align: center;
  background-color: rgb(251, 251, 251);
  max-width: 100%;
}
.inline {
  display: inline;
}
