.background {
  background-image: linear-gradient(
    to bottom left,
    rgb(151, 205, 250),
    rgb(176, 137, 250)
  );
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.errorPage{
  position: absolute;
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}

.container {
  max-width: 600px;
  text-align: center;
}

.header404 {
  font-size: 16vw;
  line-height: 1em;
  position: relative;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom left,
    rgb(151, 205, 250),
    rgb(176, 137, 250)
  );
  background-size: 300%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255,255,255,0.25);
}

.subheader {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;
}

.text {
  font-size: 1em;
  color: #0d0d0d;
}

.buttons {
  margin: 25px 0;
  display: inline-flex;
}

.button {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid rgb(176, 137, 250);
  background: #fff;
  color: rgb(176, 137, 250);
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.button:hover {
background: linear-gradient(
    to bottom left,
    rgb(151, 205, 250),
    rgb(176, 137, 250)
  );
  border: 2px solid #fff;
  color: #fff;
}

