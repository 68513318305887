.container {
  width: 100%;
  margin-top: 20px;
}
.formControl {
  background-color: #c4c4c4;
  color: black;
  border-radius: 16px;
}
.formControl:focus {
  background-color: #c4c4c4;
  color: black;
}
.title {
  font-size: 18px;
  margin: 0;
  padding-left: 20px;
}
.multiSubtitle {
  font-size: 12px;
  margin: 0;
  padding: 0 0 10px 20px;
}
.optionalSubhedding {
  font-size: 12px;
  margin: 0px;
  padding: 0px 0px 10px 20px;
}
