.center {
  justify-content: center;
  align-items: center;
}

.fullWidthCenter {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0;
}

.ddpContainer {
  width: 50%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.fullHeight {
  min-height: 100vh;
  height: 100%;
  align-items: center;
}

.width75 {
  width: 75%;
}
.width100 {
  width: 100%;
}
.width90 {
  width: 90%;
}
.width80 {
  width: 80%;
}
.height40 {
  height: 40%;
  margin-right: 20px;
}
.height75 {
  height: 75%;
}
.padding0 {
  padding: 0;
}
.fontSize78 {
  font-size: 48px;
}
.fontSize64 {
  font-size: 32px;
}
.marginTop100 {
  margin-top: 100px;
}
.headerImage {
  justify-content: center;
  align-items: center;
  display: flex;
}
.aboutUsImage {
  width: 90%;
}
.button {
  background-color: #f6d030;
  color: #053e5e;
  border-color: white;
}
.button:hover {
  background-color: #fdce04;
  color: #053e5e;
  border-color: #053e5e;
}
.bg {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.ddpRow {
  align-items: center;
}
.ddpText {
  font-size: 2.5vw;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ddpBlurb {
  width: 50%;
  margin-top: 50px;
}

.faqImage {
  width: 100%;
}

/* Medium breakpoint */
@media (max-width: 768px) {
  .title {
    margin-top: 20vh;
    margin-left: 10vw;
  }
  .headerImage {
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .aboutUsImage {
    margin-bottom: 5vh;
  }
}

/* Large breakpoint */
@media (max-width: 992px) {
  .ddpRow {
    justify-content: center;
  }
  .ddpBlurb {
    width: 100%;
  }
  .faqImage {
    margin-bottom: 40px;
  }
}
