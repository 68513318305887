.gradientBackground {
  background-size: cover;
}
.loading {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
}
.accessDenied {
  height: 100vh;
  width: 100vw;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
}
.heading {
  font-size: 3rem;
  text-align: center;
  margin: 5vh 0 5vh 0;
}
.disclaimerText {
  margin-top: 25px;
  font-size: 11px;
  text-align: center;
  width: 100%;
}
.spinnerContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  margin: 10px 0px 10px 0px;
}
.errorContainer {
  margin-top: 20px;
}
.centerAlignText {
  text-align: center;
}
.disclaimerText {
  text-align: center;
  width: 70%;
  margin-bottom: 40px;
}
.loadingText {
  margin: 0 0 0 10px;
}
