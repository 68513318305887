.button {
  width: 100%;
  border-radius: 36px;
  background-color: #18cf52;
  border-color: #18cf52;
  padding: 3vh 6vh;
}
/* .button:active {
  background-color: #2585BB !important;
  border-color: #2585bb !important;
}
.button:hover {
  background-color: #2585BB !important;
  border-color: #2585bb !important;
}
.button:focus {
  background-color: #2585BB !important;
  border-color: #2585bb !important;
} */
.disabled {
  background-color: #18cf52 !important;
  border-color: #18cf52 !important;
  opacity: 0.4 !important;
}