.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.modal-main {
    position: fixed;
    background: white;
    width: 70%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    border: #2d0371 solid 1px;
    background-color: #d8def3;
    padding: 20px 30px 20px 30px;
    overflow-y: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 15px;
}
::-webkit-scrollbar-track {
    background: white;
}
::-webkit-scrollbar-thumb {
    background: #98b2c2;
}
::-webkit-scrollbar-thumb:hover {
    background: slategray;
}
.display-block {
    display: block;
}
.display-none {
    display: none;
}
