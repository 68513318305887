.complete {
  font-weight: bold;
  color: green;
  background-color: #DDDDDD;
  padding: 3% 20%;
  display: flex;
}
.incomplete {
  font-weight: bold;
  color: red;
  background-color: #DDDDDD;
  padding: 3% 20%;
  display: flex;
}
