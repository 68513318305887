.container {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}
.centerEvents {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventsDisplay {
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 20px;
}

p {
  margin: 2px;
}

.card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.22);
  width: 100%;
  height: 80%;
}
.col {
  padding-left: 5%;
  flex-direction: column;
}

.col h3{
  font-size: 1.7rem;
  margin-bottom: 2vh;
}

