@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap');


.background {
  background-image: linear-gradient(
    to bottom left,
    rgb(151, 205, 250),
    rgb(176, 137, 250)
  );
  width: 100%;
  min-height: 80vh;
  height: 100%;
}

html{
  scroll-behavior: smooth;
}

.positionRelative {
  position: relative;
  height: 100%;
}

.sponsorsBackground {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #ece2ff;
}

.backgroundInverted {
  background-image: linear-gradient(
    to bottom right,
    rgb(176, 137, 250),
    rgb(151, 205, 250)
  );
  width: 100%;
  min-height: 70vh;
  height: 100%;
}

.width100 {
  width: 100%;
}
.width80 {
  width: 80%;
}
.height70 {
  min-height: 70vh;
  height: 70%;
  align-items: center;
  text-align: center;
}
.height100 {
  height: 100%;
}
.fullHeight {
  min-height: 60vh;
  height: 100%;
  align-items: center;
}
.topLeft {
  width: 100%;
  height: 100%;
}

.centerVertically {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}

.particles {
  position: absolute;
  height: 100%;
  width: 100%;
}
.h1 {
  font-family: 'New Amsterdam', monospace;
  font-weight: 700;
  font-size: 4rem; /* Adjusted font size */
  color: #fdd04b;
  white-space: nowrap; /* Prevents line breaks */
  /* -webkit-text-stroke: 1px black; Uncomment if needed */
}

.p1 {
  font-family: 'Poppins', monospace;
  font-size: 1.5rem;
  font-weight: 400;
  color: white;
}
.h2_25rem {
  font-family: 'New Amsterdam', monospace;
  font-size: 2.5rem;
}

.h2 {
  font-family: 'New Amsterdam', monospace;
  font-size: 3rem;
  font-weight: 700;
  color: #fdd04b;
  -webkit-text-stroke: 0.01px black;
  text-align: center;
}

.takeOnChallengeHeader {
  font-family: 'New Amsterdam', monospace;
  font-size: 3rem;
  font-weight: 700;
  color: #fdd04b;
  -webkit-text-stroke: 0.01px black;
  text-align: center;
  padding-top: 60px;
}

.h1_2rem {
  font-family: 'New Amsterdam', monospace;
  font-size: 2rem;
}
.alignItemsCenter {
  align-items: center;
}
.flexCenter {
  justify-content: center;
  display: flex;
}
.marginTop10 {
  margin-top: 10vh;
}
.white {
  font-family: 'Poppins', monospace;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  text-align: center;
}
.whoWeAre {
  text-align: center;
  margin-top: 40px;
}

.whoWeAreTitle {
  font-family: 'New Amsterdam', monospace;
  font-size: 3rem;
  font-weight: 700;
  color: #fdd04b;
  -webkit-text-stroke: 0.01px black;
}

.whoWeAreBody {
  font-family: 'Poppins', monospace;
  font-size: 1rem;
  font-weight: 400;
  color: white;
}

.ourVision {
padding-top: 40px;
}

.ourVisionTitle {
  font-family: 'New Amsterdam', monospace;
  font-size: 3rem;
  font-weight: 700;
  color: #fdd04b;
  -webkit-text-stroke: 0.01px black;
}

.ourVisionBody {
  font-family: 'Poppins', monospace;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  text-align: center;
}

.applyButtonContainer {
  width: 245px;
  align-items: left;
  margin-left: 140px;
}
.applyButton {
  font-family: 'New Amsterdam', monospace;
  font-size: 22px;
  font-weight: 600;
  background-color: #f6d030;
  color: #053e5e;
  border-color: black;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  padding: 0rem 0.75rem;
  margin-top: 40px;
  margin-bottom: 60px;
}

.applyButton:hover {
  background-color: #fdce04;
  border-color: white;
}



.statsContainer{
  height: 90%;
  width:100%;
  position: relative;
  border: solid;
  border-color: red;
  border-width: 0px;
  padding-top: 400px;
}
.statsOne{
  position: absolute;
  left: 7%;
  bottom: 70%;
  height: 225px;
  width: 225px;
  background-color: #f6d030;
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsTwo{
  position: absolute;
  left: 50%;
  bottom: 43%;
  height: 225px;
  width: 225px;
  background-color: rgb(115, 93, 188);
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsThree{
  position: absolute;
  left: 0%;
  bottom: 30%;
  height: 200px;
  width: 200px;
  background-color: rgb(115, 93, 188);
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsFour{
  position: absolute;
  left: 75%;
  bottom: 10%;
  height: 200px;
  width: 200px;
  background-color: #f6d030;
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsFive{
  position: absolute;
  left: 30%;
  bottom: 5%;
  height: 175px;
  width: 175px;
  background-color: #20acf7;
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsSix{
  position: absolute;
  left: 85%;
  bottom: 73%;
  height: 175px;
  width: 175px;
  background-color: #20acf7;
  border-radius: 30px;
  box-shadow: 5px 5px 3px gray;
  display: table;
}
.statsFontBig {
  font-family: 'New+Amsterdam', monospace;
  font-size: 2.1rem;
  font-weight: 700;
  color: white;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.statsFontMid {
  font-family: 'New+Amsterdam', monospace;
  font-size: 1.95rem;
  font-weight: 700;
  color: white;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.statsFontSm{
  font-family: 'New+Amsterdam', monospace;
  font-size: 1.85rem;
  font-weight: 700;
  color: white;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.gearOne{
  position: absolute;
  left: 75%;
  bottom: 55%;
}
.gearTwo{
  position: absolute;
  left: 30%;
  bottom: 45%;
}
.gearThree{
  position: absolute;
  left: 35%;
  bottom: 90%;
}
.gearFour{
  position: absolute;
  left: 55%;
  bottom: 15%;
}
.gearFive{
  position: absolute;
  left: 10%;
  bottom: 5%;
}
.gearSix{
  position: absolute;
  left: -5%;
  bottom: 65%;
}
.gearSeven{
  position: absolute;
  left: 65%;
  bottom: 85%;
}
.gearEight{
  position: absolute;
  left: 100%;
  bottom: 35%;
}

.cont{
  height: 650px;
}

.cont2{
  height: 550px;
}

.takeOnChallenge {
  text-align: center;
}

.computerContainer {
  height: 90%;
  margin-top: -2px;
  width:100%;
  position: relative;
}

.computer {
  position: absolute;
  height: 900px;
  right: 10%;
  bottom: -15%;
}

.carousel {
  width: 800px;
  position: absolute;
  right: 5.2%;
  bottom: 27%;
}

.computerImage {
  height: 373px;
  width: 593px;
  border-radius: 3px;
}


/* Medium breakpoint */
@media (max-width: 768px) {
  .title {
    padding-top: 20vh;
  }
  .background {
    padding-bottom: 10vh;
  }
}
