.background {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15vh 0 15vh;
}

.backgroundCentered {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 15vh 0 15vh;
  text-align: center;
}
