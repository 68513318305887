.container {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.card {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.22);
  width: 100%;
  height: 75%;
}
.col {
  text-align: left;
  display: flex;
  align-items: left;
  /*justify-content: center;*/
  flex-direction: column;
}
