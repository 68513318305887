.container {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.spinner {
  height: 50px;
  width: 50px;
}
.text {
  text-align: center;
  margin: 30px 0 0 0;
}
