.col1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.col2 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
