.width100 {
  width: 100%;
}
.width75 {
  width: 75%;
}
.silver {
  max-height: 350px;
}
.width50 {
  height: 100%;
  max-width: 60%;
}
.width35 {
  width: 35%;
}
.pb10 {
  padding-top: 7vh;
  padding-bottom: 10vh;
}
.col {
  margin-top: 30px;
  text-align: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.marginTop100 {
  margin-top: 100px;
  font-size: 2rem;
  text-align: center;
}
.h1_2021 {
  font-family: 'Roboto Mono', monospace;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: rgb(115, 93, 188);
}
.h1_2021_marginTop150 {
  font-family: 'Roboto Mono', monospace;
  margin-top: 150px;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: rgb(115, 93, 188);
}
.justifyContentCenter {
  justify-content: center;
}
.underline {
  text-decoration: underline;
}
.platinumParagraph {
  margin-top: 15px;
  text-align: center;
  width: 100%;
  font-family: 'Roboto Mono', monospace;
  font-size: 18px;
}
