.blue {
  color: #053e5e;
  margin-right: 15px;
  font-family: "Montserrat";
}
.yellow {
  color: #f6d030;
}
.white {
  color: white;
  margin-right: 15px;
  font-family: "Montserrat";
}
.icon {
  margin-right: 10px;
}
.container {
  padding-left: 50px;
  padding-right: 50px;
  background-color: rgb(115, 93, 188);
  position: relative;
}
.button {
  background-color: #f6d030;
  color: #053e5e;
  border-color: white;
}
.button:hover {
  background-color: #fdce04;
  color: #053e5e;
  border-color: #053e5e;
}
.applyButton {
  background-color: #f6d030;
  color: #053e5e;
  border-color: white;
  width: 100%;
  padding: 0rem 0.75rem;
}
.applyButton:hover {
  background-color: #fdce04;
  border-color: white;
}

.items {
  margin-left: auto;
  align-items: center;
}

/* Medium breakpoint */
@media (min-width: 768px) {
  .items {
    margin-right: 80px;
  }
}
